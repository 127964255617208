import React, { useEffect, useRef, useState } from 'react'
import { Grid, Card, CardActions, CardContent, FontIcon, TableContainer, Table, TableHeader, TableRow, TableCell, TableBody } from 'react-md'
import { useReactToPrint } from 'react-to-print'
import { FormContext, useForm } from 'react-hook-form'
import { format, parseISO } from 'date-fns'

import { Cell, PageTitle, ButtonEdit, ButtonSearch, ButtonClearSearch, Callout } from 'components/core'
import { handleSubmit, Input, InputDatePicker, Select } from 'components/form'
import { ClientAutoComplete } from 'components/auto-complete'
import { CalloutType } from 'components/core/callout'
import { DeleteButton } from 'components/data-table'
import { EmptyResultMessage } from 'types/core/api'

import { PrintContentDeclarationClientOrderEnum, PrintContentDeclarationClientOrderOptions } from './types'
import { defaultValues, validationSchema } from './config'
import { handleSearch } from './actions'

import Print from './components/print'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [orders, setOrders] = useState<Array<any>>()
  const componentRef = useRef(null)
  const order = form.watch('order')

  useEffect(() => {
    document.title = 'Impressão de Declaração'

    return () => {}
  }, [])

  useEffect(() => {
    if (!order) form.setValue<string, string>('order', PrintContentDeclarationClientOrderEnum.OrderNumber)
  }, [order])

  const load = async () => {
    const res = await handleSearch(form.getValues())
    if (res) setOrders(res)
  }

  const removeOrder = (orderId: string) => setOrders(orders?.filter(x => x.orderId !== orderId))

  const handlePrintHTML = useReactToPrint({ content: () => componentRef.current, documentTitle: 'Declaração de Conteúdo' })

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Impressão de Declaração' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <ClientAutoComplete name='client' label='Cliente' desktopSize={6} />
                  <InputDatePicker name='initialCreationDate' label='Data de Criação Inicial *' desktopSize={3} tabletSize={4} />
                  <InputDatePicker name='finalCreationDate' label='Data de Criação Final *' desktopSize={3} tabletSize={4} />
                  <Input name='recipientName' label='Destinatário' desktopSize={6} tabletSize={4} />
                  <Input name='trackingCode' label='Tracking' desktopSize={3} tabletSize={4} />
                  <Input name='orderNumber' label='Núm. Pedido' desktopSize={3} tabletSize={4} />
                  <Input name='printingLotId' label='Núm. do Lote de Impressão' desktopSize={3} tabletSize={4} />
                  <Select name='order' label='Ordem *' options={PrintContentDeclarationClientOrderOptions} desktopSize={3} tabletSize={4} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, async () => await load())} />
              <ButtonEdit
                text='Imprimir'
                icon={<FontIcon>print</FontIcon>}
                disabled={!orders || orders.length === 0}
                onClick={async () => handlePrintHTML()}
              />
              <ButtonClearSearch
                disabled={!form.formState.dirty}
                onClick={async () => {
                  form.reset(defaultValues, { dirty: false })
                }}
              />
            </CardActions>
          </Card>
        </Cell>
        <Cell>
          {orders?.length! > 0 && (
            <Card fullWidth>
              <CardContent>
                <TableContainer>
                  <Table fullWidth>
                    <TableHeader>
                      <TableRow>
                        <TableCell>Data de Criação</TableCell>
                        <TableCell>Pedido</TableCell>
                        <TableCell>Destinatário</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {orders?.map((data: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell>{format(parseISO(data.createdAt), "dd'/'MM'/'yyyy")}</TableCell>
                          <TableCell>{data.orderNumber}</TableCell>
                          <TableCell>{data.recipientName}</TableCell>
                          <TableCell align='right'>
                            <DeleteButton id={`delete-${index}`} action={() => removeOrder(data.orderId)} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}

          {orders?.length === 0 && <Callout text={EmptyResultMessage} type={CalloutType.Info} />}
        </Cell>
      </Grid>

      {orders && orders.length > 0 && (
        <div style={{ display: 'none' }}>
          <Print orders={orders} ref={componentRef} />
        </div>
      )}
    </>
  )
}

export default Component
