export interface StatusList {
  value: StatusEnum | null
  label: string
}

export enum StatusEnum {
  InReturnProcess = 'InReturnProcess',
  PleaseDisregardPreviousInformation = 'PleaseDisregardPreviousInformation',
  AwaitingResendNewTry = 'AwaitingResendNewTry',
  TransferBetweenUnits = 'TransferBetweenUnits',
}

export const StatusOptions: Array<StatusList> = [
  { value: null, label: '' },
  { value: StatusEnum.AwaitingResendNewTry, label: 'Aguardando Reenvio | Nova Tentativa' },
  { value: StatusEnum.InReturnProcess, label: 'Em Processo de Devolução' },
  { value: StatusEnum.PleaseDisregardPreviousInformation, label: 'Favor Desconsiderar Informação Anterior' },
  { value: StatusEnum.TransferBetweenUnits, label: 'Transferência Entre Unidades' },
]
