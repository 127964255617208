import Yup from 'utils/yup'

export const defaultValues = {
  status: null,
  volumeIds: '',
}

export const validationSchema = Yup.object().shape({
  status: Yup.string().nullable().required(),
  volumeIds: Yup.string().nullable().required(),
})
