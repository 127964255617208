import React, { useEffect, useRef, useState } from 'react'
import { Card, CardActions, CardContent, FontIcon, ListItem, TabPanel, TabPanels, Tabs, TabsManager } from 'react-md'
import { useParams } from 'react-router-dom'
import { Grid, useToggle } from '@react-md/utils'
import { useReactToPrint } from 'react-to-print'

import { handlePrintProtocol } from 'pages/order/protocol/print/actions'
import { Actions, ButtonEdit, Cell, PageTitle } from 'components/core'
import PrintProtocol from 'pages/order/protocol/print'
import { StatusEnum } from 'types/order/status'

import OperationalInformation from './components/operational-information'
import Recipient from './components/recipient'
import RecipientAddress from './components/recipient-address'
import TaxInformation from './components/tax-information'
import FinancialInformation from './components/financial-information'
import DeliveryInformation from './components/delivery-information'
import Ticket from './components/ticket'
import Tracking from './components/tracking'
import ChangeService from './components/change-service'

import { handleGet } from './actions'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [visibleChangeServiceModal, enableChangeServiceModal, disableChangeServiceModal] = useToggle(false)
  const [order, setOrder] = useState<any>()
  const [loadTrackings, setLoadTrackings] = useState<boolean>(false)
  const [loadTickets, setLoadTickets] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [printProtocol, setPrintProtocol] = useState<any>()
  const [visibleActions, enableActions, disableActions] = useToggle(false)
  const componentRef = useRef(null)

  useEffect(() => {
    document.title = 'Edição de Pedido'

    load()

    return () => {}
  }, [id])

  useEffect(() => {
    if (activeTab === 1) setLoadTrackings(true)
    else if (activeTab === 2) setLoadTickets(true)

    return () => {}
  }, [activeTab])

  const load = async () => {
    const res = await handleGet(id)
    if (res) setOrder(res)
  }

  const print = async () => {
    const res = await handlePrintProtocol(id)
    if (res) setPrintProtocol(res)
  }

  const handlePrintHTML = useReactToPrint({ content: () => componentRef.current, documentTitle: 'Protocolo' })

  return (
    <>
      <Grid>
        <Cell>
          {order && (
            <>
              <PageTitle title='Edição de Pedido' />
              <TabsManager
                tabs={['Pedido', 'Trackings', 'Tickets']}
                activeIndex={activeTab}
                onActiveIndexChange={activeIndexNumber => setActiveTab(activeIndexNumber)}
                tabsId={'tab-order'}>
                <Tabs />
                <TabPanels persistent={true}>
                  <TabPanel>
                    <Card fullWidth>
                      <CardContent>
                        <OperationalInformation order={order} />
                        <Recipient order={order} />
                        <RecipientAddress order={order} />
                        <TaxInformation order={order} />
                        <FinancialInformation order={order} />
                        <DeliveryInformation order={order} print={print} />
                      </CardContent>
                      <CardActions align='end'>
                        <ButtonEdit text='Ações' icon={<FontIcon>settings</FontIcon>} onClick={async () => enableActions()} />
                      </CardActions>
                    </Card>
                  </TabPanel>
                  <TabPanel>{loadTrackings && <Tracking volumeId={id} />}</TabPanel>
                  <TabPanel>{loadTickets && <Ticket orderId={order.orderId} />}</TabPanel>
                </TabPanels>
              </TabsManager>
            </>
          )}
        </Cell>
      </Grid>

      <Actions visible={visibleActions} disable={disableActions}>
        <ListItem
          leftAddon={<FontIcon>published_with_changes</FontIcon>}
          disabled={order?.status !== StatusEnum.Created && order?.status !== StatusEnum.TransferBetweenUnits}
          onClick={() => enableChangeServiceModal()}>
          Alterar Serviço
        </ListItem>
      </Actions>

      {visibleChangeServiceModal && <ChangeService orderId={order.orderId} disableModal={disableChangeServiceModal} loadOrder={load} />}

      {printProtocol && (
        <div style={{ display: 'none' }}>
          <PrintProtocol protocol={printProtocol} handlePrint={handlePrintHTML} ref={componentRef} />
        </div>
      )}
    </>
  )
}

export default Component
