import Yup from 'utils/yup'

export const recipientDefaultValues = {
  name: null,
  document: null,
  ie: null,
  email: null,
  phone: null,
}

export const recipientValidationSchema = Yup.object().shape({
  name: Yup.string().nullable().required().max(100, 'O nome deve ter no máximo 100 caracteres'),
  document: Yup.string().nullable().required().max(19, 'O cpf/cnpj deve ter no máximo 19 caracteres'),
  ie: Yup.string().nullable().max(14, 'A inscrição estadual deve ter no máximo 14 caracteres'),
  email: Yup.string().nullable().max(80, 'O e-mail deve ter no máximo 80 caracteres'),
  phone: Yup.string().nullable().max(11, 'O telefone deve ter no máximo 11 caracteres'),
})
