import Yup from 'utils/yup'

export const defaultValues = {
  client: {
    id: null,
  },
  service: null,
  isDeclaration: null,
  name: null,
  document: null,
  ie: null,
  volumes: null,
  email: null,
  phone: null,
  nature: null,
  weight: null,
  orderNumber: null,
  fiscalNoteNumber: null,
  fiscalNoteSerie: null,
  fiscalNoteDate: null,
  fiscalNoteAccessKey: null,
  totalValue: null,
}

export const validationSchema = Yup.object().shape({
  client: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  service: Yup.string().nullable().required(),
  isDeclaration: Yup.boolean().nullable().required(),
  name: Yup.string().nullable().required().min(4, 'O nome deve ter no mínimo 4 caracteres').max(100, 'O nome deve ter no máximo 100 caracteres'),
  document: Yup.string().nullable().required().max(19, 'O cpf/cnpj deve ter no máximo 19 caracteres'),
  ie: Yup.string().nullable().max(14, 'A inscrição estadual deve ter no máximo 14 caracteres'),
  volumes: Yup.number().nullable().required().min(1, 'Quantidade de volumes não informada').max(99, 'Os volumes devem conter no máximo 99'),
  email: Yup.string().nullable().max(80, 'O e-mail deve ter no máximo 80 caracteres'),
  phone: Yup.string().nullable().max(11, 'O telefone deve ter no máximo 11 caracteres'),
  nature: Yup.string().nullable().max(60, 'A natureza/descrição deve ter no máximo 60 caracteres'),
  weight: Yup.number().nullable(),
  orderNumber: Yup.string()
    .nullable()
    .max(50, 'O pedido deve ter no máximo 50 caracteres')
    .test('orderNumber', 'Campo obrigatório', function (value) {
      if (!value && this.parent.isDeclaration) return false
      return true
    }),
  fiscalNoteNumber: Yup.string()
    .nullable()
    .max(30, 'A nota fiscal deve ter no máximo 30 caracteres')
    .test('fiscalNoteNumber', 'Campo obrigatório', function (value) {
      if (!value && !this.parent.isDeclaration) return false
      return true
    }),
  fiscalNoteSerie: Yup.number()
    .nullable()
    .test('fiscalNoteSerie', 'Campo obrigatório', function (value) {
      if (!value && !this.parent.isDeclaration) return false
      return true
    }),
  fiscalNoteDate: Yup.date()
    .nullable()
    .test('fiscalNoteDate', 'Campo obrigatório', function (value) {
      if (!value && !this.parent.isDeclaration) return false
      return true
    }),
  fiscalNoteAccessKey: Yup.string()
    .nullable()
    .max(45, 'A chave da nota fiscal  deve ter no máximo 45 caracteres')
    .test('fiscalNoteAccessKey', 'Campo obrigatório', function (value) {
      if (!value && !this.parent.isDeclaration) return false
      return true
    }),
  totalValue: Yup.number().nullable().required(),
})
