import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, TableCell, TableRow, FontIcon } from 'react-md'

import { Cell, PageTitle, ButtonSearch, ButtonEdit, ButtonClearSearch } from 'components/core'
import { DataTableResult } from 'components/data-table/types'
import { IbgeAutoComplete } from 'components/auto-complete'
import { formatCurrency } from 'types/core/currency'
import { DataTable } from 'components/data-table'
import { handleSubmit } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleDownloadCsv, handleSearch } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })

  useEffect(() => {
    document.title = 'Pesquisa de Iss'

    return () => {}
  }, [])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Pesquisa de Iss' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <IbgeAutoComplete name='city' label='Cidade' desktopSize={6} tabletSize={8} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSearch
                onClick={async () =>
                  await handleSubmit(form, validationSchema, () => {
                    let values = form.getValues()
                    let city: any = values.city
                    setFilter({ cityIbgeCode: city ? city.id : null })
                  })
                }
              />
              <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
              <ButtonEdit text='Download' icon={<FontIcon>download</FontIcon>} onClick={async () => await handleDownloadCsv('Iss.csv')} />
            </CardActions>
          </Card>
        </Cell>
        <Cell>
          <DataTable
            filter={filter}
            header={[{ order: 'cityIbgeCode', label: 'Cód. Ibge da Cidade' }, { order: 'city', label: 'Cidade' }, { label: 'Alíquota (%)' }]}
            result={result}
            setResult={setResult}
            search={handleSearch}
            total={result ? result.total : 0}>
            {result &&
              result.results &&
              result.results.map((data: any, index) => (
                <TableRow key={index}>
                  <TableCell>{data.cityIbgeCode}</TableCell>
                  <TableCell>{data.city}</TableCell>
                  <TableCell>{formatCurrency(data.value)}</TableCell>
                </TableRow>
              ))}
          </DataTable>
        </Cell>
      </Grid>
    </>
  )
}

export default Component
