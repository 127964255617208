import { trackPromise } from 'react-promise-tracker'
import { AxiosRequestConfig } from 'axios'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'
import { DataTableRequest } from 'components/data-table/types'

const headerConfig: AxiosRequestConfig = {
  responseType: 'arraybuffer',
}

const url = '/v1/ibge'
const Api = {
  import: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
  search: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search`, request)),
  getByZipCode: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/get-by-zip-code`, request)),
  autocomplete: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/autocomplete`, request)),
  downloadCsv: (): Promise<any> => trackPromise<ApiResponse>(api.post(`${url}/csv`, null, headerConfig)),
}

export default Api
