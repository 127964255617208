import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, TableCell, TableRow, FontIcon } from 'react-md'

import { Cell, PageTitle, ButtonSearch, ButtonClearSearch, ButtonEdit } from 'components/core'
import { ZipCode } from 'components/address/components/zip-code'
import { DataTableResult } from 'components/data-table/types'
import { formatCepMask } from 'components/address/types'
import { handleSubmit, Input } from 'components/form'
import { DataTable } from 'components/data-table'

import { handleDownloadCsv, handleSearch } from './actions'
import { defaultValues, validationSchema } from './config'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })

  useEffect(() => {
    document.title = 'Pesquisa Ibge'

    return () => {}
  }, [])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Pesquisa Ibge' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <ZipCode name='zipCode' label='Cep' maxLength={8} desktopSize={2} tabletSize={3} />
                  <Input name='city' label='Cidade' desktopSize={4} tabletSize={3} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, () => setFilter(form.getValues()))} />
              <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
              <ButtonEdit text='Download' icon={<FontIcon>download</FontIcon>} onClick={async () => await handleDownloadCsv('Ibge.csv')} />
            </CardActions>
          </Card>
        </Cell>
        <Cell>
          <DataTable
            filter={filter}
            header={[{ order: 'InitialZipCode', label: 'Faixa de Cep' }, { label: 'Código / Estado' }, { label: 'Código / Cidade' }]}
            result={result}
            setResult={setResult}
            search={handleSearch}
            total={result ? result.total : 0}>
            {result &&
              result.results &&
              result.results.map((data: any, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {formatCepMask(data.initialZipCode)} - {formatCepMask(data.finalZipCode)}
                  </TableCell>
                  <TableCell>
                    {data.stateIbgeCode} - {data.state}
                  </TableCell>
                  <TableCell>
                    {data.cityIbgeCode} - {data.city}
                  </TableCell>
                </TableRow>
              ))}
          </DataTable>
        </Cell>
      </Grid>
    </>
  )
}

export default Component
