import api from 'services/system/ibge'
import { DataTableRequest } from 'components/data-table/types'

import downloadFile from 'utils/download-file'

export const handleSearch = async (request: DataTableRequest) => {
  let res = await api.search(request)

  return res?.data ?? []
}

export const handleDownloadCsv = async (fileName: string) => {
  let res = await api.downloadCsv()

  downloadFile(fileName, res?.data)
}
