import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'

const url = '/v1/order/report'
const Api = {
  incidentClientReport: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/incident/client`, request)),
  incidentFmReport: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/incident/fm`, request)),
  ordersClientReport: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/orders/client`, request)),
  ordersFmReport: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/orders/fm`, request)),
  ordersWithProblemsClientReport: (request: any): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.post(`${url}/orders-with-problems/client`, request)),
  ordersWithProblemsFmReport: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/orders-with-problems/fm`, request)),
  performanceClientReport: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/performance/client`, request)),
  performanceFmReport: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/performance/fm`, request)),
  searchLotClientReport: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search-lot/client`, request)),
  searchLotFmReport: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search-lot/fm`, request)),
  stoppedOrdersReport: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/stopped-orders`, request)),
}

export default Api
