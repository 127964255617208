import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, Text, FontIcon, useToggle } from 'react-md'
import { toast } from 'react-toastify'

import { Cell, PageTitle, ButtonSuccess, ConfirmDialog, Callout } from 'components/core'
import { handleSubmit, InputArea, Select } from 'components/form'
import { CalloutType } from 'components/core/callout'

import { defaultValues, validationSchema } from './config'
import { handleImport } from './actions'
import { StatusOptions } from './types'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [visibleUpdate, enableUpdate, disableUpdate] = useToggle(false)

  useEffect(() => {
    document.title = 'Atualização de Status Manual'

    return () => {}
  }, [])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Atualização de Status Manual' />
          <Card fullWidth>
            <CardContent>
              <Callout text={'Para realizar a atualização de status informe o status desejado e os ids dos volumes'} type={CalloutType.Info} />
              <FormContext {...form}>
                <Grid>
                  <Select name='status' label='Status *' options={StatusOptions} desktopSize={6} tabletSize={8} />
                </Grid>
                <Grid>
                  <InputArea name='volumeIds' label='Volumes *' rows={6} maxRows={6} desktopSize={6} tabletSize={8} />
                  <Cell>
                    <Text type='caption' component='h1'>
                      <i>Para realizar a atualização informe no máximo de 1000 volumes</i>
                    </Text>
                  </Cell>
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSuccess
                text='Atualizar'
                icon={<FontIcon>done</FontIcon>}
                disabled={!form.formState.dirty}
                onClick={async () =>
                  await handleSubmit(form, validationSchema, async () => {
                    enableUpdate()
                  })
                }
              />
            </CardActions>
          </Card>
        </Cell>
      </Grid>

      <ConfirmDialog
        text='Deseja realmente atualizar os pedidos? Essa ação não poderá ser desfeita'
        visible={visibleUpdate ? true : false}
        confirmAction={async () => {
          disableUpdate()
          let formValues = form.getValues()
          let values: Array<string> = []

          if (formValues.volumeIds) {
            let itens = formValues.volumeIds as string
            itens.split('\n').forEach((value: string) => {
              if (value) values.push(value)
            })

            if (values.length > 1000) {
              toast.warn(`Não é permitido atualizar mais de 1000 volumes`)
              return
            }

            await handleImport({ status: formValues.status, volumeIds: values })

            form.reset(defaultValues, { dirty: false })
          }
        }}
        cancelAction={async () => disableUpdate()}
      />
    </>
  )
}

export default Component
