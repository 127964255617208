import Yup from 'utils/yup'

export const defaultValues = {
  email: null,
  login: null,
  code: null,
  newPassword: null,
  confirmNewPassword: null,
}

export const validationSchema = Yup.object().shape({
  email: Yup.string().nullable().required().email('E-mail inválido').max(80, 'O email deve ter no máximo 80 caracteres'),
  login: Yup.string().nullable().required().min(5, 'O login deve ter no mínimo 5 caracteres').max(20, 'O login deve ter no máximo 20 caracteres'),
  code: Yup.string().nullable().required(),
  newPassword: Yup.string()
    .nullable()
    .required()
    .min(8, 'A senha deve ter no mínimo 8 caracteres')
    .max(40, 'A senha deve ter no máximo 40 caracteres')
    .test('passwords-match', 'A confirmação de senha está incorreta', function (value) {
      return this.parent.confirmNewPassword === value
    })
    .matches(new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*'), {
      message: 'Informe uma senha que contenha um ou mais números, letras maiúsculas e letras minúsculas',
    }),
  confirmNewPassword: Yup.string()
    .nullable()
    .required()
    .min(8, 'A senha deve ter no mínimo 8 caracteres')
    .max(40, 'A senha deve ter no máximo 40 caracteres')
    .test('passwords-match', 'A confirmação de senha está incorreta', function (value) {
      return this.parent.newPassword === value
    })
    .matches(new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*'), {
      message: 'Informe uma senha que contenha um ou mais números, letras maiúsculas e letras minúsculas',
    }),
})
